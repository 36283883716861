var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('br'),_c('v-card',{staticStyle:{"margin-left":"1%","margin-right":"1%","margin-top":"2%","font-family":"'Montserrat' sans-serif","border-radius":"10px 10px 10px 10px"},attrs:{"max-width":"98%","max-height":"15000"}},[_c('div',[_c('v-card',[_c('v-card-title',{staticStyle:{"font-size":"17px"}},[_c('v-spacer'),_c('v-spacer'),_c('b',[_vm._v("BANCOS")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',[_c('v-sheet',[_c('div',{staticStyle:{"position":"relative","float":"right"}},[_c('router-link',{staticClass:"link",attrs:{"to":"/addbanco"}},[_c('v-btn',{staticStyle:{"color":"white"},attrs:{"color":"green"}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" AGREGAR BANCO")])],1)],1),_c('div',{staticStyle:{"position":"relative","float":"left"}},[_c('p',[_c('b',[_vm._v(_vm._s(_vm.reservas)+" bancos /")]),_vm._v(" "+_vm._s(_vm.page)+" páginas")])])])],1),_c('br'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.desserts,"single-select":_vm.singleSelect,"search":_vm.search,"item-key":"clave","show-select":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-switch',{staticClass:"pa-3",attrs:{"label":"Single select"},model:{value:(_vm.singleSelect),callback:function ($$v) {_vm.singleSelect=$$v},expression:"singleSelect"}})]},proxy:true},{key:"item.clave",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":"/detalles"}},[_c('br'),_c('v-icon',{staticClass:"mr-2",staticStyle:{"color":"#818181","font-size":"14px","border":"1px solid #818181","border-radius":"100px"},attrs:{"small":""}},[_vm._v(" fas fa-ellipsis-h ")]),_c('span',[_vm._v(_vm._s(item.clave))]),_c('br'),_c('br')],1)]}},{key:"item.tres",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(_vm.formatPercent(item.tres)))])]}},{key:"item.seis",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(_vm.formatPercent(item.seis)))])]}},{key:"item.nueve",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(_vm.formatPercent(item.nueve)))])]}},{key:"item.doce",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(_vm.formatPercent(item.doce)))])]}},{key:"item.normal",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(_vm.formatPercent(item.normal)))])]}},{key:"item.banco",fn:function(ref){
var item = ref.item;
return [(item.img)?_c('img',{staticStyle:{"width":"18px"},attrs:{"src":item.img}}):_vm._e(),_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(item.banco))])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)]),_c('br')],1)}
var staticRenderFns = []

export { render, staticRenderFns }